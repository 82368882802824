$ ->
  $('.stage-select').change ->
    companyId = $(this).data('id')

    $.ajax
      url: '/update_stage/' + companyId
      method: 'POST'
      data: { stage: @value }
      success: ->
        location.reload()
      error: (error) ->
        console.log(error.responseText)
